import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";

const pData = [
  2400, 1398, 9800, 3908, 4800, 3800, 4300, 2400, 1398, 9800, 3908, 4800,
];
const xLabels = [
  "03.2024",
  "04.2024",
  "05.2024",
  "06.2024",
  "07.2024",
  "08.2024",
  "09.2024",
  "10.2024",
  "11.2024",
  "12.2024",
  "01.2025",
  "02.2025",
];

const Trend = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        cursor: "pointer",
        boxSizing: "border-box",
      }}
      borderRadius="35px"
      p={4}
    >
      <Typography variant="h4">Gesamtausgaben der letzten 12 Monate</Typography>

      <Box backgroundColor="white" mt={2} p={1}>
        <LineChart
          height={300}
          series={[{ data: pData, label: "Kosten" }]}
          xAxis={[{ scaleType: "point", data: xLabels }]}
          slotProps={{
            legend: {
              position: { vertical: "bottom" },
              labelStyle: { fontSize: "10px" },
            },
            container: { responsive: true },
          }}
          margin={{ bottom: 70, top: 20 }}
        />
      </Box>
    </Box>
  );
};

export default Trend;
