export const data = [
  // march data start
  {
    id: 1,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Gehälter",
    Datum: "2025-03-13",
    Beschreibung: "Mitarbeitergehälter",
    fällig: "2024-04-13",
    Bertrag: 400.75,
  },
  {
    id: 2,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2025-03-15",
    Beschreibung: "Miete",
    fällig: "2024-04-15",
    Bertrag: 800,
  },
  {
    id: 3,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2025-03-05",
    Beschreibung: "Versicherung",
    fällig: "2024-04-05",
    Bertrag: 200,
  },
  {
    id: 4,
    Firma: "EWE",
    Kategorie: "Strom",
    Datum: "2025-03-05",
    Beschreibung: "Strom",
    fällig: "2024-04-05",
    Bertrag: 250,
  },
  {
    id: 5,
    Firma: "Ryan Air",
    Kategorie: "Reisen",
    Datum: "2025-03-05",
    Beschreibung: "Nach Italien",
    fällig: "2024-04-05",
    Bertrag: 100,
  },

  // march data end
  {
    id: 1847,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Versorgungsunternehmen",
    Datum: "2024-08-25",
    Beschreibung: "Versorgungsunternehmen",
    fällig: "2024-08-25",
    Bertrag: 89.71,
  },
  {
    id: 9047,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Marketing",
    Datum: "2025-02-16",
    Beschreibung: "Stromrechnung",
    fällig: "2025-02-16",
    Bertrag: 965.02,
  },
  {
    id: 7193,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Versorgungsunternehmen",
    Datum: "2024-03-19",
    Beschreibung: "Versorgungsunternehmen",
    fällig: "2024-03-19",
    Bertrag: 423.52,
  },
  {
    id: 5317,
    Firma: "OfficeSpace Rentals",
    Kategorie: "Gehälter",
    Datum: "2024-01-03",
    Beschreibung: "Stromrechnung",
    fällig: "2024-01-03",
    Bertrag: 846.46,
  },
  {
    id: 4479,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Marketing",
    Datum: "2024-01-11",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-01-11",
    Bertrag: 864.44,
  },
  {
    id: 7507,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Bürobedarf",
    Datum: "2024-11-20",
    Beschreibung: "Stromrechnung",
    fällig: "2024-11-20",
    Bertrag: 318.98,
  },
  {
    id: 6298,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Miete",
    Datum: "2024-12-16",
    Beschreibung: "Beratungsdienste",
    fällig: "2024-12-16",
    Bertrag: 501.21,
  },
  {
    id: 7108,
    Firma: "Global Consultants",
    Kategorie: "Marketing",
    Datum: "2024-02-21",
    Beschreibung: "Monatliche Büromiete",
    fällig: "2024-02-21",
    Bertrag: 972.12,
  },
  {
    id: 6633,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Versorgungsunternehmen",
    Datum: "2024-12-29",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-12-29",
    Bertrag: 860.19,
  },
  {
    id: 7926,
    Firma: "Global Consultants",
    Kategorie: "Gehälter",
    Datum: "2024-05-15",
    Beschreibung: "Bürobedarf",
    fällig: "2024-05-15",
    Bertrag: 322.4,
  },
  {
    id: 7132,
    Firma: "OfficeSpace Rentals",
    Kategorie: "Dienstleistungen",
    Datum: "2024-08-22",
    Beschreibung: "Dienstleistungen",
    fällig: "2024-08-22",
    Bertrag: 412.76,
  },
  {
    id: 2439,
    Firma: "OfficeSpace Rentals",
    Kategorie: "Miete",
    Datum: "2024-07-09",
    Beschreibung: "Stromrechnung",
    fällig: "2024-07-09",
    Bertrag: 462.8,
  },
  {
    id: 4737,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Dienstleistungen",
    Datum: "2024-01-27",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-01-27",
    Bertrag: 510.45,
  },
  {
    id: 9434,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Versorgungsunternehmen",
    Datum: "2024-12-08",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-12-08",
    Bertrag: 442.17,
  },
  {
    id: 9841,
    Firma: "OfficeSpace Rentals",
    Kategorie: "Versorgungsunternehmen",
    Datum: "2024-07-20",
    Beschreibung: "Stromrechnung",
    fällig: "2024-07-20",
    Bertrag: 875.48,
  },
  {
    id: 6078,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Versorgungsunternehmen",
    Datum: "2025-01-31",
    Beschreibung: "Stromrechnung",
    fällig: "2025-01-31",
    Bertrag: 914.59,
  },
  {
    id: 5858,
    Firma: "Global Consultants",
    Kategorie: "Miete",
    Datum: "2024-01-05",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-01-05",
    Bertrag: 197.82,
  },
  {
    id: 5228,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Dienstleistungen",
    Datum: "2024-12-12",
    Beschreibung: "Bürobedarf",
    fällig: "2024-12-12",
    Bertrag: 460.72,
  },
  {
    id: 8780,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Miete",
    Datum: "2024-04-13",
    Beschreibung: "Miete",
    fällig: "2024-04-13",
    Bertrag: 400.75,
  },
  {
    id: 7428,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Gehälter",
    Datum: "2024-10-27",
    Beschreibung: "Mitarbeitergehälter",
    fällig: "2024-10-27",
    Bertrag: 521.9,
  },
  {
    id: 4804,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Dienstleistungen",
    Datum: "2024-07-24",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-07-24",
    Bertrag: 857.69,
  },
  {
    id: 9443,
    Firma: "Global Consultants",
    Kategorie: "Miete",
    Datum: "2024-09-06",
    Beschreibung: "Bürobedarf",
    fällig: "2024-09-06",
    Bertrag: 186.56,
  },
  {
    id: 1548,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Marketing",
    Datum: "2024-11-29",
    Beschreibung: "Stromrechnung",
    fällig: "2024-11-29",
    Bertrag: 826.32,
  },
  {
    id: 1925,
    Firma: "Global Consultants",
    Kategorie: "Marketing",
    Datum: "2025-01-22",
    Beschreibung: "Monatliche Büromiete",
    fällig: "2025-01-22",
    Bertrag: 205.03,
  },
  {
    id: 6227,
    Firma: "Tech Innovations GmbH",
    Kategorie: "Marketing",
    Datum: "2025-01-01",
    Beschreibung: "Marketing",
    fällig: "2025-01-01",
    Bertrag: 523.63,
  },
  {
    id: 2723,
    Firma: "Global Consultants",
    Kategorie: "Miete",
    Datum: "2024-07-19",
    Beschreibung: "Stromrechnung",
    fällig: "2024-07-19",
    Bertrag: 85.06,
  },
  {
    id: 2121,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Dienstleistungen",
    Datum: "2024-10-10",
    Beschreibung: "Stromrechnung",
    fällig: "2024-10-10",
    Bertrag: 130.51,
  },
  {
    id: 2004,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Marketing",
    Datum: "2024-08-23",
    Beschreibung: "Beratungsdienste",
    fällig: "2024-08-23",
    Bertrag: 853.62,
  },
  {
    id: 1926,
    Firma: "XYZ Marketing Co.",
    Kategorie: "Dienstleistungen",
    Datum: "2024-12-12",
    Beschreibung: "Beratungsdienste",
    fällig: "2024-12-12",
    Bertrag: 511.84,
  },
  {
    id: 1705,
    Firma: "ABC Supplies Ltd.",
    Kategorie: "Marketing",
    Datum: "2024-02-09",
    Beschreibung: "Digitale Marketingkampagne",
    fällig: "2024-02-09",
    Bertrag: 987.13,
  },
  {
    id: 6403,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-01-15",
    Beschreibung: "Miete",
    fällig: "2024-01-15",
    Bertrag: 800,
  },
  {
    id: 7007,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-01-05",
    Beschreibung: "Versicherung",
    fällig: "2024-01-05",
    Bertrag: 200,
  },
  {
    id: 4020,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-02-15",
    Beschreibung: "Miete",
    fällig: "2024-02-15",
    Bertrag: 800,
  },
  {
    id: 5542,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-02-05",
    Beschreibung: "Versicherung",
    fällig: "2024-02-05",
    Bertrag: 200,
  },
  {
    id: 5214,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-03-15",
    Beschreibung: "Miete",
    fällig: "2024-03-15",
    Bertrag: 800,
  },
  {
    id: 9848,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-03-05",
    Beschreibung: "Versicherung",
    fällig: "2024-03-05",
    Bertrag: 200,
  },
  {
    id: 2292,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-04-15",
    Beschreibung: "Miete",
    fällig: "2024-04-15",
    Bertrag: 800,
  },
  {
    id: 3248,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-04-05",
    Beschreibung: "Versicherung",
    fällig: "2024-04-05",
    Bertrag: 200,
  },
  {
    id: 9718,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-05-15",
    Beschreibung: "Miete",
    fällig: "2024-05-15",
    Bertrag: 800,
  },
  {
    id: 8976,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-05-05",
    Beschreibung: "Versicherung",
    fällig: "2024-05-05",
    Bertrag: 200,
  },
  {
    id: 7163,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-06-15",
    Beschreibung: "Miete",
    fällig: "2024-06-15",
    Bertrag: 800,
  },
  {
    id: 5850,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-06-05",
    Beschreibung: "Versicherung",
    fällig: "2024-06-05",
    Bertrag: 200,
  },
  {
    id: 9326,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-07-15",
    Beschreibung: "Miete",
    fällig: "2024-07-15",
    Bertrag: 800,
  },
  {
    id: 3768,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-07-05",
    Beschreibung: "Versicherung",
    fällig: "2024-07-05",
    Bertrag: 200,
  },
  {
    id: 8195,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-08-15",
    Beschreibung: "Miete",
    fällig: "2024-08-15",
    Bertrag: 800,
  },
  {
    id: 2770,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-08-05",
    Beschreibung: "Versicherung",
    fällig: "2024-08-05",
    Bertrag: 200,
  },
  {
    id: 9385,
    Firma: "Vonovia",
    Kategorie: "Miete",
    Datum: "2024-09-15",
    Beschreibung: "Miete",
    fällig: "2024-09-15",
    Bertrag: 800,
  },
  {
    id: 4822,
    Firma: "Check24",
    Kategorie: "Versicherung",
    Datum: "2024-09-05",
    Beschreibung: "Versicherung",
    fällig: "2024-09-05",
    Bertrag: 200,
  },
];
