import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export default function Bar({ showChart = true, filteredData, start, end }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const categories = filteredData.reduce((acc, item) => {
    acc[item.Kategorie] = (acc[item.Kategorie] || 0) + item.Bertrag;
    return acc;
  }, {});

  const barData = Object.keys(categories).map((item) => ({
    Kategorie: item,
    Bertrag: categories[item],
  }));

  console.log(barData);
  const [leftMargin, setLeftMargin] = useState(0);
  useEffect(() => {
    const maxLabelLength = Math.max(
      ...barData.map((item) => item.Kategorie.length)
    );

    setLeftMargin(maxLabelLength * 10);
  }, [barData]);

  const handleClick = () => {
    navigate("/costs");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        // cursor: "pointer",
      }}
      borderRadius="35px"
      // onClick={handleClick}
    >
      <Box p={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">
            Gesamtausgaben in Kategorien für den Zeitraum
          </Typography>
          <AddCircleIcon />
        </Box>

        <Typography sx={{ fontWeight: "bold", fontSize: "50px" }}>
          €{barData.reduce((sum, item) => sum + item.Bertrag, 0).toFixed(2)}
        </Typography>

        {showChart && (
          <Box sx={{ width: "100%", height: "400px" }}>
            <BarChart
              yAxis={[
                {
                  scaleType: "band",
                  data: barData.map((item) => item.Kategorie),
                },
              ]}
              series={[
                {
                  data: barData.map((item) => item.Bertrag),
                  label:
                    start && end
                      ? `von ${start} bis ${end}`
                      : start
                      ? `von ${start}`
                      : end
                      ? `bis ${end}`
                      : "Die Ganze Zeit",
                },
              ]}
              slotProps={{
                legend: {
                  position: { vertical: "bottom" },
                  labelStyle: { fontSize: "10px" },
                },
                container: { responsive: true },
              }}
              layout="horizontal"
              margin={{ left: leftMargin, top: 20, bottom: 70 }}
              sx={{ backgroundColor: "white", borderRadius: "20px" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
