import { Box } from "@mui/material";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useTheme } from "@mui/material";
import { inputStyle, dropdownStyles } from "../../../theme";
import { data } from "../costs/tablesection/data";
import { getUpdatedData } from "../costs/expenseform/sumbitHandler";
import Select from "react-select";

const options = [];
const uniqueValues = new Set();
getUpdatedData().forEach((item) => {
  const value = item.Kategorie.toLowerCase();
  if (!uniqueValues.has(value)) {
    uniqueValues.add(value);
    options.push({
      value: value,
      label: item.Kategorie,
    });
  }
});

export const Filter = ({
  category,
  setCategory,
  max,
  setMax,
  min,
  setMin,
  start,
  setStart,
  end,
  setEnd,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      gap={2}
      p={2}
    >
      {/* Category */}
      <Box
        backgroundColor={theme.palette.primary.main}
        p={2}
        borderRadius="25px"
        display="flex"
        alignItems="center"
        gap={2}
        flex={1}
        sx={{
          overflow: "hidden",
        }}
      >
        <Select
          value={category}
          options={options}
          styles={dropdownStyles}
          isMulti
          onChange={(category) => setCategory(category)}
          placeholder="Kategorie"
          menuPortalTarget={document.body}
        />
      </Box>

      {/* Max and Min */}
      <Box display="none" gap={2} flex={1}>
        {/* Min Value */}
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          flex={1}
        >
          <EuroSymbolIcon />
          <input
            name="min"
            placeholder="Minimalwert"
            type="number"
            value={min}
            onChange={(e) => setMin(e.target.value)}
            style={inputStyle}
          />
        </Box>
        {/* Max Value */}
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          flex={1}
        >
          <EuroSymbolIcon />
          <input
            name="max"
            placeholder="Maximalwert"
            type="number"
            value={max}
            onChange={(e) => setMax(e.target.value)}
            style={inputStyle}
          />
        </Box>
      </Box>

      {/* Start and End */}
      <Box display="flex" flex={1} gap={2}>
        {/* Start Date */}
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          flex={1}
        >
          <CalendarMonthIcon />
          <input
            value={start}
            name="start"
            type="date"
            onChange={(e) => setStart(e.target.value)}
            style={inputStyle}
            placeholder="Startdatum"
          />
        </Box>

        {/* End Date */}
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          flex={1}
        >
          <CalendarMonthIcon />
          <input
            name="date"
            type="date"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            placeholder="Enddatum"
            style={inputStyle}
          />
        </Box>
      </Box>
    </Box>
  );
};
