import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { data } from "./data";
import { getUpdatedData } from "../expenseform/sumbitHandler";
import Select from "react-select";
import { inputStyle, dropdownStyles } from "../../../../theme";

const Filter = ({
  search,
  setSearch,
  category,
  setCategory,
  min,
  setMin,
  max,
  setMax,
  start,
  setStart,
  end,
  setEnd,
}) => {
  const theme = useTheme();
  //   dropdown creation
  const options = [];
  const uniqueValues = new Set();
  getUpdatedData().forEach((item) => {
    const value = item.Kategorie?.toLowerCase();
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      options.push({
        value: value,
        label: item.Kategorie,
      });
    }
  });

  return (
    <Box>
      {/* SEARCH AND CATEGORY */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
        flex={1}
        p={2}
      >
        {/* Search Bar */}
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          flex={1.5}
          display="flex"
          alignItems="center"
          gap={2}
        >
          <SearchIcon />
          <input
            placeholder="Suchen hier"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={inputStyle}
          />
        </Box>
        {/* Category */}
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          flex={1}
          sx={{
            overflow: "hidden",
          }}
        >
          <Select
            value={category}
            options={options}
            styles={dropdownStyles}
            isMulti
            onChange={(category) => setCategory(category)}
            placeholder="Kategorie"
            menuPortalTarget={document.body}
          />
        </Box>
      </Box>

      {/* SECOND ROW */}
      <Box
        display="flex"
        flex={1}
        gap={2}
        pl={2}
        pr={2}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* Max and Min */}
        <Box display="flex" gap={2} flex={1}>
          {/* Min Value */}
          <Box
            backgroundColor={theme.palette.primary.main}
            p={2}
            borderRadius="25px"
            display="flex"
            alignItems="center"
            gap={2}
            flex={1}
          >
            <EuroSymbolIcon />
            <input
              name="min"
              placeholder="Minimalwert"
              type="number"
              value={min}
              onChange={(e) => setMin(e.target.value)}
              style={inputStyle}
            />
          </Box>
          {/* Max Value */}
          <Box
            backgroundColor={theme.palette.primary.main}
            p={2}
            borderRadius="25px"
            display="flex"
            alignItems="center"
            gap={2}
            flex={1}
          >
            <EuroSymbolIcon />
            <input
              name="max"
              placeholder="Maximalwert"
              type="number"
              value={max}
              onChange={(e) => setMax(e.target.value)}
              style={inputStyle}
            />
          </Box>
        </Box>

        {/* Start and End */}
        <Box display="flex" flex={1} gap={2}>
          {/* Start Date */}
          <Box
            backgroundColor={theme.palette.primary.main}
            p={2}
            borderRadius="25px"
            display="flex"
            alignItems="center"
            gap={2}
            flex={1}
          >
            <CalendarMonthIcon />
            <input
              name="start"
              type="date"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              style={inputStyle}
              placeholder="Startdatum"
            />
          </Box>

          {/* End Date */}
          <Box
            backgroundColor={theme.palette.primary.main}
            p={2}
            borderRadius="25px"
            display="flex"
            alignItems="center"
            gap={2}
            flex={1}
          >
            <CalendarMonthIcon />
            <input
              name="date"
              type="date"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              placeholder="Enddatum"
              style={inputStyle}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Filter;
