import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material";

const convertToTopExpensesData = (data) => {
  const groupedData = {};
  console.log(data);
  data.forEach((item) => {
    const { Kategorie, Bertrag, Datum, fällig } = item;

    if (!groupedData[Kategorie]) {
      groupedData[Kategorie] = {
        title: Kategorie,
        amount: Bertrag,
        total: 0,
        date: new Date(Datum),
        recurrence: "Monat",
      };
    }

    groupedData[Kategorie].total += Bertrag;
  });

  return Object.values(groupedData).sort((a, b) => b.amount - a.amount);
};

const TopExpenses = ({ filteredData }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        borderRadius: "35px",
      }}
    >
      <Box p={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Die Top 3 Ausgaben im Detail</Typography>
        </Box>

        {convertToTopExpensesData(filteredData)
          .slice(0, 3)
          .map((item, index) => (
            <Box display="flex" gap={2} pt={2}>
              <Box width="100%">
                <Box display="flex" justifyContent="space-between">
                  <Typography sx={{ fontWeight: "medium" }} variant="h6">
                    {index + 1}. {item.title}
                  </Typography>
                  <Typography sx={{ fontWeight: "medium" }}>
                    {item.amount.toFixed(2)} €
                  </Typography>
                </Box>
                <ul>
                  <li>
                    <Typography
                      sx={{
                        fontWeight: "medium",
                        fontSize: "15px",
                        fontStyle: "italic",
                      }}
                    >
                      Zuletzt bezahlt am {item.date.toLocaleDateString()}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      sx={{
                        fontWeight: "medium",
                        fontSize: "15px",
                        fontStyle: "italic",
                      }}
                    >
                      Wiederholt sich jeden {item.recurrence}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      sx={{
                        fontWeight: "medium",
                        fontSize: "15px",
                        fontStyle: "italic",
                      }}
                    >
                      Gesamtbetrag im Zeitraum: €{item.total.toFixed(2)}
                    </Typography>
                  </li>
                </ul>
                <Divider
                  sx={{
                    borderColor: "black",
                    width: "100%",
                    my: 2, //  vertical spacing
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default TopExpenses;
