import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserNavBar from "./user/UserNavBar";
import Dashboard from "./user/pages/dashboard";
import Costs from "./user/pages/costs";
import AI from "./user/pages/ai";
import Reports from "./user/pages/reports";
import Accounts from "./user/pages/accounts";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserNavBar />}>
          <Route index element={<Dashboard />} />
          <Route path="costs" element={<Costs />} />
          <Route path="ai_suggestions" element={<AI />} />
          <Route path="reports" element={<Reports />} />
          <Route path="accounts" element={<Accounts />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
