import { Typography, Box } from "@mui/material";
import Month from "./Month";
import AI_PREDICTION from "./AI-Prediction";
import AI_SAVINGS from "./AI-Savings";
import Upcoming from "./Upcoming";
import Log from "./Log";
import Trend from "./Trend";

const Dashboard = () => {
  return (
    <Box
      sx={{
        width: "100%", // full width of the container
        padding: "3% 3%", // uniform padding (top-bottom, left-right)
        boxSizing: "border-box", // ensures padding is included in the width
      }}
    >
      {/* Header */}
      <Typography variant="h3" mb={3}>
        Willkommen in Ihrem Dashboard
      </Typography>

      {/* Month Expenses & AI Suggestions */}
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Box flex={1} p={2}>
          <Month />
        </Box>

        <Box display="flex" flexDirection="column" flex={1}>
          <Box flex={1} p={2}>
            <AI_PREDICTION />
          </Box>
          <Box flex={2} p={2}>
            <AI_SAVINGS />
          </Box>
        </Box>
      </Box>

      {/* Upcoming & Log */}
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Box flex={1} p={2}>
          <Upcoming />
        </Box>

        <Box flex={1} p={2}>
          <Log />
        </Box>
      </Box>

      {/* Last 12 Months */}
      <Box display="flex" p={2} gap={2} flex={1}>
        <Trend />
      </Box>
    </Box>
  );
};

export default Dashboard;
