import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

const data = [
  {
    name: "Name des Benutzers",
    date: "Hinzugefügte Ausgabe “Andere“ am 20. März",
  },
  {
    name: "Name der Ausgabe",
    date: "Hinzugefügte Ausgabe “Andere“ am 17. März",
  },
  {
    name: "Name der Ausgabe",
    date: "Hinzugefügte Ausgabe “Andere“ am 7. März",
  },
];

const Log = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ai_suggestions");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        // cursor: "pointer",
        borderRadius: "35px",
      }}
      // onClick={handleClick}
    >
      <Box p={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Anstehende Ausgaben</Typography>
        </Box>

        {data.map((item) => {
          return (
            <Box display="flex" gap={2} pt={2}>
              <PersonIcon />
              <Box width="100%">
                <Typography sx={{ fontWeight: "medium" }}>
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "medium",
                    fontSize: "15px",
                    fontStyle: "italic",
                  }}
                >
                  {item.date}
                </Typography>
                <Divider
                  sx={{
                    borderColor: "black",
                    width: "100%",
                    my: 2, //  vertical spacing
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Log;
