import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Filter from "./Filter";
import { AllCostsTable } from "./AllCostsTable";
import { useTheme } from "@mui/material";

export const TableSection = () => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  return (
    <Box>
      <Box display="flex" p={4} pb={0} flex={1}>
        <Box>
          <Typography
            fontWeight="medium"
            fontSize="40px"
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Alle Kosten
          </Typography>
        </Box>
      </Box>
      <Box>
        <Filter
          search={search}
          setSearch={setSearch}
          category={category}
          setCategory={setCategory}
          min={min}
          setMin={setMin}
          max={max}
          setMax={setMax}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
        />
      </Box>
      <Box p={2}>
        <AllCostsTable
          search={search}
          category={category}
          min={min}
          max={max}
          start={start}
          end={end}
        />
      </Box>
    </Box>
  );
};
