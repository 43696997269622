import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SquareIcon from "@mui/icons-material/Square";

const data = [
  {
    name: "Name der Ausgabe",
    amount: "€150",
    due: "20. März",
  },
  {
    name: "Name der Ausgabe",
    amount: "€350",
    due: "25. März",
  },
  {
    name: "Name der Ausgabe",
    amount: "€50",
    due: "27. März",
  },
];

const Upcoming = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ai_suggestions");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        // cursor: "pointer",
        borderRadius: "35px",
      }}
      // onClick={handleClick}
    >
      <Box p={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Anstehende Ausgaben</Typography>
        </Box>

        {data.map((item) => {
          return (
            <Box display="flex" gap={2} pt={2}>
              <SquareIcon />
              <Box width="100%">
                <Typography sx={{ fontWeight: "medium" }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <span>{item.name}</span>
                    <span>{item.amount}</span>
                  </Box>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "medium",
                    fontSize: "15px",
                    fontStyle: "italic",
                  }}
                >
                  Fälling am {item.due}
                </Typography>
                <Divider
                  sx={{
                    borderColor: "black",
                    width: "100%",
                    my: 2, //  vertical spacing
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Upcoming;
