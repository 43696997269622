import { createTheme } from "@mui/material";
import "@fontsource/inter"; // Specify weight and style

export const theme = createTheme({
  palette: {
    primary: {
      main: "#D9D9D9",
      light: "#D9D9D9",
      dark: "#939496",
    },
  },

  typography: {
    color: "#000000",
    fontFamily: "Inter",
    fontStyle: "none",
  },

  navbar: {
    color: "#000000",
  },
});

export const inputStyle = {
  backgroundColor: theme.palette.primary.main,
  border: "none",
  fontFamily: theme.typography.fontFamily,
  fontWeight: "medium",
  outline: "none",
  boxShadow: "none",
  fontSize: "1rem",
  flex: 1,
  width: "90%",
};

export const dropdownStyles = {
  control: (provided) => ({
    ...provided,
    ...inputStyle,
    display: "flex",
    width: "100%",
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected
      ? theme.palette.primary.dark
      : isFocused
      ? theme.palette.primary.light
      : "white",
    color: isSelected ? "white" : theme.palette.text.primary,
    padding: "10px",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.palette.text.secondary,
  }),
};
