import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const pieChartData = [
  { id: 0, value: 960, label: "Einsparungen", color: "#8AFFF1" },
  { id: 1, value: 4500, label: "Ausgaben", color: "#FF928A" },
];

<PieChart
  series={[
    {
      data: pieChartData,
      innerRadius: 50, // Donut chart
      outerRadius: 100,
      highlightScope: { fade: "global", highlight: "item" },
      faded: { innerRadius: 40, additionalRadius: -20, color: "gray" },
    },
  ]}
  slotProps={{
    legend: { hidden: true }, // Removes the legend
    container: { responsive: true },
  }}
  style={{ width: "100%", height: "400px" }} // Ensures proper sizing
/>;

const AISavings = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        alignContent: "center",
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
      }}
      borderRadius="35px"
      p={4}
    >
      <Typography variant="h4" mb={3}>
        KI Einsparungen
      </Typography>

      <PieChart
        series={[
          {
            data: pieChartData,
            highlightScope: { fade: "global", highlight: "item" },

            innerRadius: 80,
          },
        ]}
        slotProps={{
          // legend: {
          //   position: { vertical: "bottom", horizontal: "right" },
          // },
          legend: { hidden: true },
          container: { responsive: true },
        }}
        style={{
          height: "350px",
          width: "100%",
        }}
        margin={{ right: 0, left: 0, top: 0, bottom: 0 }}
      />
    </Box>
  );
};
export default AISavings;
