import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getUpdatedData } from "../costs/expenseform/sumbitHandler";

export default function Month({ showChart = true }) {
  const categoryMap = {};
  const theme = useTheme();

  getUpdatedData().forEach((item) => {
    const todayDate = new Date();
    const itemDate = new Date(item.Datum);

    if (
      todayDate.getMonth() === itemDate.getMonth() &&
      todayDate.getFullYear() === itemDate.getFullYear()
    ) {
      if (categoryMap[item.Kategorie]) {
        categoryMap[item.Kategorie] += item.Bertrag;
      } else {
        categoryMap[item.Kategorie] = item.Bertrag;
      }
    }
  });

  var barChartData = Object.keys(categoryMap).map((key) => ({
    Kategorie: key,
    Bertrag: categoryMap[key],
  }));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
      }}
      borderRadius="35px"
    >
      <Box p={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">
            Gesamtausgaben für Marz bis heute
          </Typography>
          <AddCircleIcon />
        </Box>

        <Typography sx={{ fontWeight: "bold", fontSize: "50px" }}>
          €
          {barChartData.reduce((sum, item) => sum + item.Bertrag, 0).toFixed(2)}
        </Typography>

        {showChart && (
          <Box sx={{ width: "100%", height: "400px" }}>
            <BarChart
              yAxis={[
                {
                  scaleType: "band",
                  data: barChartData.map((item) => item.Kategorie),
                },
              ]}
              series={[
                {
                  data: barChartData.map((item) => item.Bertrag),
                  label: "03.2025",
                },
              ]}
              slotProps={{
                legend: {
                  position: { vertical: "bottom" },
                  labelStyle: { fontSize: "10px" },
                },
                container: { responsive: true },
              }}
              layout="horizontal"
              margin={{ left: 90, top: 20, bottom: 70 }}
              sx={{ backgroundColor: "white", borderRadius: "20px" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
