import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";

const Trend = ({ filteredData }) => {
  const theme = useTheme();

  const Months = filteredData
    .sort((a, b) => new Date(a.Datum) - new Date(b.Datum))
    .reduce((acc, item) => {
      const itemDate = new Date(item.Datum);
      const xLabel = new Intl.DateTimeFormat("de-DE", {
        month: "2-digit",
        year: "numeric",
      }).format(itemDate);

      acc[xLabel] = (acc[xLabel] || 0) + item.Bertrag;
      return acc;
    }, {});

  const dataPoints = Object.entries(Months).map(([xLabel, yData]) => ({
    xLabel,
    yData,
  }));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        cursor: "pointer",
        boxSizing: "border-box",
      }}
      borderRadius="35px"
      p={4}
    >
      <Typography variant="h4">Gesamtausgaben für den Zeitraum</Typography>

      <Box backgroundColor="white" mt={2} p={1}>
        <LineChart
          height={300}
          series={[
            {
              data: dataPoints.map((dataPoints) => dataPoints.yData),
              label: "Kosten",
            },
          ]}
          xAxis={[
            {
              scaleType: "point",
              data: dataPoints.map((dataPoints) => dataPoints.xLabel),
            },
          ]}
          slotProps={{
            legend: {
              position: { vertical: "bottom" },
              labelStyle: { fontSize: "10px" },
            },
            container: { responsive: true },
          }}
          margin={{ bottom: 70, top: 20 }}
        />
      </Box>
    </Box>
  );
};

export default Trend;
