import { data } from "../tablesection/data";

const handleFormSubmit = (costData) => {
  const datum = new Date(costData.dueDate);

  console.log(costData);
  data.push({
    id: data.length + 1,
    Kategorie: costData.category,
    Firma: costData.companyName,
    Bertrag: Number(costData.fee),
    Datum: new Date(datum.setDate(datum.getDate())).toISOString().split("T")[0],
  });
};

export const getUpdatedData = () => data;
export { handleFormSubmit };
