import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { getUpdatedData } from "./expenseform/sumbitHandler";

export const ExpensesChart = () => {
  const categoryMap = {};
  const theme = useTheme();

  getUpdatedData().forEach((item) => {
    const todayDate = new Date();
    const itemDate = new Date(item.Datum);

    if (
      todayDate.getMonth() === itemDate.getMonth() &&
      todayDate.getFullYear() === itemDate.getFullYear()
    ) {
      if (categoryMap[item.Kategorie]) {
        categoryMap[item.Kategorie] += item.Bertrag;
      } else {
        categoryMap[item.Kategorie] = item.Bertrag;
      }
    }
  });

  var barChartData = Object.keys(categoryMap).map((key) => ({
    Kategorie: key,
    Bertrag: categoryMap[key],
  }));

  const seriesData = barChartData.map((data) => ({
    value: data.Bertrag,
    label: data.Kategorie,
  }));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        alignContent: "center",
        boxSizing: "border-box",
      }}
      borderRadius="35px"
      p={4}
    >
      <PieChart
        series={[
          {
            data: seriesData,
            highlightScope: { fade: "global", highlight: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          },
        ]}
        slotProps={{
          legend: {
            position: { vertical: "middle", horizontal: "right" },
          },
          container: { responsive: true },
        }}
      />
    </Box>
  );
};
