import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { data } from "./data";
import { getUpdatedData } from "../expenseform/sumbitHandler";

export const AllCostsTable = ({ search, category, min, max, start, end }) => {
  const numMin = parseFloat(min) || 0;
  const numMax = parseFloat(max) || Infinity;
  const startDate = new Date(start);
  const endDate = new Date(end);
  const categorySet = new Set();
  category &&
    category.forEach((item) => categorySet.add(item.value.toLowerCase()));

  const theme = useTheme();
  const [pageSize, setPageSize] = useState(20);

  const columns = [
    { field: "Firma", headerName: "Firma", flex: 1, minWidth: 200 },
    { field: "Kategorie", headerName: "Kategorie", flex: 1, minWidth: 150 },
    { field: "Bertrag", headerName: "Betrag", flex: 1, minWidth: 120 },
    { field: "Datum", headerName: "Datum", flex: 1, minWidth: 150 },
    {
      field: "Beschreibung",
      headerName: "Beschreibung",
      flex: 2,
      minWidth: 200,
    },
  ];

  console.log(getUpdatedData());

  return (
    <Box
      sx={{
        height: "70vh",
        width: "100%",
        p: 2,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: 2,
      }}
    >
      <DataGrid
        rows={getUpdatedData()
          .filter(
            (item) =>
              item.Firma &&
              item.Firma.toLowerCase().includes(search.toLowerCase())
          )
          .filter((item) =>
            categorySet.size > 0
              ? categorySet.has(item.Kategorie?.toLowerCase())
              : true
          )
          .filter((item) => numMin < parseFloat(item.Bertrag))
          .filter((item) => numMax > parseFloat(item.Bertrag))
          .filter((item) =>
            !isNaN(startDate.getTime())
              ? startDate < new Date(item.Datum)
              : true
          )
          .filter((item) =>
            !isNaN(endDate.getTime()) ? endDate > new Date(item.Datum) : true
          )
          // adding the euro sign infront of the amount
          .map((item) => ({ ...item, Bertrag: "€" + item.Bertrag }))}
        columns={columns}
        getRowId={(row) => row.id}
        disableSelectionOnClick
        pagination
        pageSizeOptions={[20, 50, 100]}
        paginationModel={{ pageSize, page: 0 }}
        onPaginationModelChange={(model) => setPageSize(model.pageSize)}
        sx={{
          "& .MuiDataGrid-columnHeaders div[role='row']": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      />
    </Box>
  );
};
