import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SwapVertIcon from "@mui/icons-material/SwapVert";

const data = [
  {
    name: "XYZ-Abonnement kündigen",
    desc: "Dieses Abonnement wurde in den letzten 3 Monaten nicht genutzt",
    amount: 210,
  },
  {
    name: "Wechseln Sie den Anbieter für die XYZ-Aktivität",
    desc: "Wir haben eine günstigere und zuverlässigere Alternative in der Nähe Ihres Unternehmensstandorts gefunden",
    amount: 110,
  },
];

const AI = () => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(true);
  const sortedData = data
    .sort((a, b) => (sort ? b.amount - a.amount : a.amount - b.amount))
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  console.log(sortedData);

  const inputStyle = {
    backgroundColor: theme.palette.primary.main,
    border: "none",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "medium",
    outline: "none",
    boxShadow: "none",
    fontSize: "1rem",
    flex: 1,
    width: "90%",
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "3% 3%",
        boxSizing: "border-box",
      }}
    >
      {/* Header */}
      <Typography variant="h3" mb={3}>
        KI-Vorschläge
      </Typography>

      {/* Search and sort */}
      <Box display="flex" flexDirection="row" p={2} gap={2}>
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          width="40%"
        >
          <SearchIcon />
          <input
            placeholder="Suchen hier"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={inputStyle}
          />
        </Box>
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          width="auto"
          onClick={() => setSort((prev) => !prev)}
          sx={{ cursor: "pointer" }}
        >
          <SwapVertIcon />
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              display: { xs: "none", md: "block" },
            }}
          >
            Sortieren
          </Typography>
        </Box>
      </Box>

      {/* Suggestions */}
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        {sortedData.length > 0 ? (
          sortedData.map((item) => {
            return (
              <Box
                backgroundColor={theme.palette.primary.main}
                p={4}
                gap={2}
                flex={1}
                borderRadius="25px"
                flexDirection={{ xs: "column", md: "row" }}
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="h5">{item.name}</Typography>
                  <Typography variant="body">{item.desc}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle">Sparen Sie</Typography>
                  <Typography variant="h4">€{item.amount}</Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontStyle: "italic",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              Leider, Keine Ergebnisse
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AI;
