import { Typography, Box, Button } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AI_PREDICTION from "../dashboard/AI-Prediction";
import Month from "../dashboard/Month";
import { ExpensesChart } from "./ExpenseChart";
import { TableSection } from "./tablesection/TableSection";
import CostModal from "./expenseform/CostModal";
import { useState } from "react";

const Costs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleAddCost = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "3% 3%",
        boxSizing: "border-box",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography variant="h3" mb={3}>
          Ausgabenübersicht
        </Typography>
        <Box
          backgroundColor="black"
          borderRadius="20px"
          display="flex"
          p={2}
          m={{ xs: 2, sm: 0, md: 0 }}
          alignItems="center"
          height="fit-content"
          onClick={handleAddCost}
          sx={{ cursor: "pointer" }}
        >
          <ControlPointIcon sx={{ color: "white" }} />
          <Button sx={{ fontWeight: "bold" }}>Kosten hinzufügen</Button>
        </Box>
      </Box>

      {/* Costs and Pie Chart */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
        p={2}
      >
        <Box
          flex={1}
          flexDirection="column"
          display="flex"
          gap={2}
          height="100%"
        >
          <Box flex={1}>
            <Month showChart={false} />
          </Box>
          <Box flex={1}>
            <AI_PREDICTION />
          </Box>
        </Box>
        <Box flex={1} display={{ xs: "none", sm: "none", md: "flex" }}>
          <ExpensesChart />
        </Box>
      </Box>

      {/* TABLE SECTION */}
      <TableSection />
      <CostModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Costs;
