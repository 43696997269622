import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";

const AI_PREDICTION = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ai_suggestions");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        // cursor: "pointer",
      }}
      borderRadius="35px"
      // onClick={handleClick}
    >
      <Box p={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">KI-Prognose für diesen Monat</Typography>
        </Box>

        <Typography sx={{ fontWeight: "bold", fontSize: "50px" }}>
          €<CountUp end={3500} duration={1} />
        </Typography>
      </Box>
    </Box>
  );
};
export default AI_PREDICTION;
