import { Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const data = [
  {
    name: "Amin LastName",
    desc: "ist seit dem 01.02.2025 Benutzer",
  },
  {
    name: "Irfan Karmali",
    desc: "ist seit dem 01.02.2025 Benutzer",
  },
  {
    name: "XYZ Username123",
    desc: "ist seit dem 01.02.2025 Benutzer",
  },
];

const Accounts = () => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const inputStyle = {
    backgroundColor: theme.palette.primary.main,
    border: "none",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "medium",
    outline: "none",
    boxShadow: "none",
    fontSize: "1rem",
    flex: 1,
    width: "90%",
  };

  const sortedData = data.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );
  console.log(sortedData);
  return (
    <Box
      sx={{
        width: "100%",
        padding: "3% 3%",
        boxSizing: "border-box",
      }}
    >
      {/* Header */}
      <Typography variant="h3" mb={3}>
        Konten
      </Typography>

      {/* Search and sort */}
      <Box display="flex" flexDirection="row" p={2} gap={2}>
        <Box
          backgroundColor={theme.palette.primary.main}
          p={2}
          borderRadius="25px"
          display="flex"
          alignItems="center"
          gap={2}
          width={{ xs: "100%", md: "40%" }}
        >
          <SearchIcon />
          <input
            placeholder="Suchen hier"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={inputStyle}
          />
        </Box>
      </Box>

      {/* Suggestions */}
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        {sortedData.length > 0 ? (
          sortedData.map((item) => {
            return (
              <Box
                backgroundColor={theme.palette.primary.main}
                p={2}
                gap={2}
                flex={1}
                borderRadius="25px"
                flexDirection={{ xs: "column", md: "row" }}
                display="flex"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={2}
                  alignItems="anchor-center"
                >
                  <Box display={{ xs: "none", md: "flex" }}>
                    <AccountCircleIcon
                      sx={{ fontSize: "70px", padding: "0", margin: "0" }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h5">{item.name}</Typography>
                    <Typography variant="body">{item.desc}</Typography>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" alignSelf="center">
                  <Button
                    sx={{
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: "20px",
                      padding: "15px",
                      color: theme.typography.color,
                      fontWeight: "medium",
                    }}
                  >
                    Bearbeiten
                    <TuneIcon sx={{ paddingLeft: "10px" }} />
                  </Button>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontStyle: "italic",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              Leider, Keine Ergebnisse
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Accounts;
