import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SquareIcon from "@mui/icons-material/Square";

const AI_SAVINGS = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ai_suggestions");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "100%",
        // cursor: "pointer",
        borderRadius: "35px",
      }}
      // onClick={handleClick}
    >
      <Box p={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">KI schlug Einsparungen vor</Typography>
        </Box>

        <Box display="flex" gap={2} pt={2}>
          <SquareIcon />
          <Box width="100%">
            <Typography sx={{ fontWeight: "medium" }}>
              Nicht genutzte Abonnements kündigen:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                fontStyle: "italic",
              }}
            >
              Kündigen Sie das XYZ-Abonnement (sparen Sie 100 €/Monat)
            </Typography>
            <Divider
              sx={{
                borderColor: "black",
                width: "90%",
                my: 2, //  vertical spacing
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AI_SAVINGS;
