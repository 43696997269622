import { Typography, Box } from "@mui/material";
import { Filter } from "./Filter";
import { useState } from "react";
import Trend from "./Trend";
import Bar from "./Bar";
import { data } from "../costs/tablesection/data";
import { getUpdatedData } from "../costs/expenseform/sumbitHandler";
import AISavings from "./AISavings";
import TopExpenses from "./TopExpenses";

const Reports = () => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState("");
  const categorySet = new Set();
  category &&
    category.forEach((item) => categorySet.add(item.value.toLowerCase()));

  const filteredData = getUpdatedData()
    .filter((item) =>
      !isNaN(new Date(start).getMonth())
        ? new Date(item.Datum) > new Date(start)
        : true
    )
    .filter((item) =>
      !isNaN(new Date(end).getMonth())
        ? new Date(item.Datum) < new Date(end)
        : true
    )
    .filter((item) =>
      categorySet.size > 0
        ? categorySet.has(item.Kategorie?.toLowerCase())
        : true
    );

  return (
    <Box
      sx={{
        width: "100%",
        padding: "1% 3%",
        boxSizing: "border-box",
      }}
    >
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" mb={3}>
          Berichte
        </Typography>
      </Box>

      {/* Filter */}
      <Box>
        <Filter
          search={search}
          setSearch={setSearch}
          category={category}
          setCategory={setCategory}
          min={min}
          setMin={setMin}
          max={max}
          setMax={setMax}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
        />
      </Box>

      {/* Line and Bar Chart */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
        p={2}
      >
        <Box display="flex" flex={1.5}>
          <Bar filteredData={filteredData} start={start} end={end} />
        </Box>
        <Box display="flex" flex={1}>
          <TopExpenses filteredData={filteredData} />
        </Box>
      </Box>

      {/* Rank and Pie Chart */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
        p={2}
      >
        <Box display="flex" flex={2}>
          <Trend filteredData={filteredData} />
        </Box>
        <Box flex={1}>
          <AISavings />
        </Box>
      </Box>

      {/* This display is hidden or equal to none */}
      <Box
        display="none"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
        p={2}
      >
        <Box backgroundColor="red" p={2} flex={1}></Box>
      </Box>
    </Box>
  );
};

export default Reports;
