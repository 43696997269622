import { Outlet, Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsIcon from "@mui/icons-material/Settings";

const UserNavBar = () => {
  const theme = useTheme();
  console.log(theme.typography.color);
  return (
    <>
      <nav
        style={{
          backgroundColor: theme.palette.primary.main,
          margin: 0,
          padding: "10px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Logo on the left */}
        <div>
          <Link
            to="/"
            style={{
              color: theme.navbar.color,
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            <img src="../logo.png" alt="logo" style={{ height: "72px" }} />
          </Link>
        </div>

        {/* Navigation links on the right */}
        <div>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
              display: "flex",
              gap: "20px",
              alignItems: "center",
              fontSize: "1.2em",
              fontFamily: theme.typography.fontFamily,
            }}
          >
            <li>
              <Link
                to="/"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                Armaturenbrett
              </Link>
            </li>
            <li>
              <Link
                to="/costs"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                Kosten
              </Link>
            </li>
            <li>
              <Link
                to="/ai_suggestions"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                KI-Vorschläge
              </Link>
            </li>
            <li>
              <Link
                to="/reports"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                Berichte
              </Link>
            </li>
            <li>
              <Link
                to="/accounts"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                Konten
              </Link>
            </li>
            <li>
              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 25,
                }}
              />
            </li>
            <li>
              <Link
                to="/notification"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                <NotificationsNoneIcon style={{ verticalAlign: "centre" }} />
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                style={{
                  color: theme.typography.color,
                  textDecoration: "none",
                }}
              >
                <SettingsIcon style={{ verticalAlign: "center" }} />
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Wrap Outlet in a div to control spacing */}
      <div
        style={{ minHeight: "100vh", padding: "20px", background: "#f4f4f4" }}
      >
        <Outlet />
      </div>
    </>
  );
};

export default UserNavBar;
