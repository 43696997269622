import React, { useState } from "react";
import Modal from "react-modal";
import "./styles.css"; // Your custom CSS file
import { handleFormSubmit } from "./sumbitHandler";

const categories = [
  "Gehälter",
  "Miete",
  "Versicherung",
  "Strom",
  "Reisen",
  "Versorgungsunternehmen",
  "Marketing",
  "Bürobedarf",
  "Dienstleistungen",
];

const CostModal = ({ isOpen, onClose, onSubmit }) => {
  const [category, setCategory] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [fee, setFee] = useState("");
  const [dueDate, setDueDate] = useState();
  const [recurrence, setRecurrence] = useState("");
  const [description, setdescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit({ category, companyName, fee, dueDate, recurrence });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose} // Close on outside click
      contentLabel="Add Cost Modal"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <span className="close" onClick={onClose}>
        &times;
      </span>
      <h2>Add Cost</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="companyName">Company Name:</label>
        <input
          type="text"
          id="Firma"
          name="Firma"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />

        <label htmlFor="category">Category:</label>
        <select
          id="Katagorie"
          name="Katagorie"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a category
          </option>
          {categories.map((category) => (
            <option value={category}>{category}</option>
          ))}
        </select>

        <label htmlFor="dueDate">Due Date:</label>
        <input
          type="date"
          id="Datum"
          name="Datum"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          required
        />

        <label htmlFor="fee">Fee (€):</label>
        <input
          type="number"
          id="Bertrag"
          name="Bertrag"
          value={fee}
          onChange={(e) => setFee(e.target.value)}
          required
        />

        <label htmlFor="recurrence">Recurrence:</label>
        <select
          id="recurrence"
          name="recurrence"
          value={recurrence}
          onChange={(e) => setRecurrence(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a recurrence
          </option>
          <option value="1">Daily</option>
          <option value="14">Bi-Weekly</option>
          <option value="7">Weekly</option>
          <option value="30">Monthly</option>
          <option value="365">Yearly</option>
        </select>

        <label htmlFor="description">Description:</label>
        <input
          type="text"
          id="description"
          name="description"
          value={description}
          onChange={(e) => setdescription(e.target.value)}
          required
        />

        <button type="submit">Submit</button>
      </form>
    </Modal>
  );
};

export default CostModal;
